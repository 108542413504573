<template>
  <svg
    width="10"
    height="16"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.37373 14.5231L7.96497 7.93189L2.16922 0.999723"
      stroke="currentColor"
      stroke-width="2"
    />
  </svg>
</template>
